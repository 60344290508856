<template>
  <v-container v-show="baseInfo.length > 0" class="mt-4">
    <v-card class="elevation-2 px-2 py-1">
      <v-card-title>
        {{ $t("基本情報") }}
      </v-card-title>
      <v-data-table
        dense
        hide-default-footer
        :headers="headers"
        :items="baseInfo"
        class="elevation-0"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'BaseInfo',
  props: {
    baseInfo: Array
  },
  data () {
    return {
      headers: [
        { text: this.$t('項目'), align: 'center', value: 'name' },
        { text: this.$t('内容'), align: 'start', value: 'value' }
      ]
    }
  },
  methods: {
  },
  created () {
  }
}
</script>
