<template>
  <v-container class="mt-12">
    <v-row justify="center" align-content="center">
      <v-form @submit.prevent="signin">
        <v-text-field
          prepend-inner-icon="mdi-account"
          label="User ID"
          v-model="username"
          required
        >
        </v-text-field>
        <v-text-field
          prepend-inner-icon="mdi-lock"
          label="password"
          v-model="password"
          :append-icon="showPasseord ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasseord ? 'text' : 'password'"
          @click:append="showPasseord = !showPasseord"
          required
        >
        </v-text-field>
        <v-btn class="mt-6" type="submit" color="primary" block>sign-in</v-btn>
      </v-form>
    </v-row>
    <v-row justify="center" align-content="center" class="mt-8" v-if="signinError">
      <p class="subtitle-2 text-center red--text">ユーザーIDまたはパスワードが正しくありません。</p>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      username: '',
      password: '',
      showPasseord: false,
      signinError: false
    }
  },
  methods: {
    signin () {
      this.$cognito.signin(this.username, this.password)
        .then(result => {
          this.signinError = false
          this.$router.replace('/home')
        })
        .then(err => {
          this.error = err
        })
        .catch(err => {
          console.error(err)
          this.signinError = true
        })
    }
  }
}
</script>
