// const fs = require('fs')

module.exports = {
  readFile: (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const base64EncodedFile = reader.result.replace(/data:.*\/.*;base64,/, '')
        resolve(base64EncodedFile)
      }, false)
      reader.addEventListener('error', (err) => {
        reject(err)
      }, false)
      reader.readAsDataURL(file)
    })
  }
}
