import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Upload'
import cognito from '@/cognito'
import store from '@/store'
import Signin from '@/components/Signin'

Vue.use(VueRouter)

const signout = (to, from, next) => {
  cognito.signout()
  next('/signin')
}

const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiredAuth: true }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  //   meta: { requiredAuth: true }
  // },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: { notRequiredAuth: true }
  },
  {
    path: '/signout',
    beforeEnter: signout
  },
  {
    path: '*',
    redirect: 'home'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  // Get signin session.
  cognito.isAuthenticated()
    .then(session => { // eslint-disable-line
      const token = session.idToken.jwtToken
      // get attribute.
      cognito.getAttribute()
        .then(result => {
          var user = {}
          for (var v of result) {
            user[v.getName()] = v.getValue()
          }
          user.token = token
          store.commit('setUser', user)
        })
        .catch(error => {
          store.commit('setUser', null)
          console.log(error)
          signout(to, from, next)
        })
      if (to.matched.some(record => record.meta.notRequiredAuth)) {
        next({
          path: '/'
        })
      }
    })
    .catch(error => { // eslint-disable-line
      store.commit('setUser', null)
      if (to.matched.some(record => record.meta.requiredAuth)) {
        next({
          path: '/signin',
          query: { redirect: to.fullPath }
        })
      }
    })
  next()
})

export default router
