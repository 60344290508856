import Vue from 'vue'
import App from './App.vue'
import router from './router'
import cognito from './cognito'
import store from './store'
import Loading from './Loading.vue'
import mitt from 'mitt'
import i18n from './plugins/i18n'

window.gEmitter = mitt()

const vuetify = process.env.NODE_ENV === 'production'
  ? require('./plugins/vuetify').default
  : require('./plugins/vuetify-dev').default

Vue.config.productionTip = false

new Vue({
  router,
  cognito,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

new Vue({
  vuetify,
  render: h => h(Loading)
}).$mount('#loading')
