<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-container v-if = "$i18n.locale == 'ja'" > {{ app_title_ja }} <label class="text-body-2 mt-8">{{ app_version_name }}</label></v-container>
        <v-container v-else> {{ app_title }} <label class="text-body-2 mt-8">{{ app_version_name }}</label></v-container>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-show="isAuthenticated()"
        class="mx-2"
        text
        @click="signout"
        style="text-transform: none"
        >sign-out</v-btn
      >
      <!-- <label class="text-body-2 mt-8">ver.2021.03.04</label> -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data: () => ({
    app_version_name: 'version.' + require('../package.json').version,
    app_title: process.env.VUE_APP_TITLE,
    app_title_ja: process.env.VUE_APP_TITLE_JA
  }),
  methods: {
    isAuthenticated () {
      return this.$store.getters.user != null
    },
    signout () {
      this.$router.replace('/signout')
    }
  },
  beforeCreate () {
    /* eslint-disable no-undef */
    window.gApigClient = apigClientFactory.newClient()
    /* eslint-enable no-undef */
  }
}
</script>
