<template>
  <v-container class="mt-4">
    <v-card class="elevation-1 px-2 py-1">
      <v-row>
        <v-col class="title pa-6">{{ $t("運転傾向") }}</v-col>
      </v-row>
      <v-row>
        <v-col v-show="showDtDescription" class="px-6 py-7 dt-description">{{dtDescription}}</v-col>
      </v-row>
      <v-data-table
        dense
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-0"
      ></v-data-table>
    </v-card>
    <v-row class="mt-1" justify="end" v-show="!isValidOperationTimeByLoad">
      <v-col md="7" style="white-space: pre;">{{notApplicable}}</v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'DrivingTendency',
  props: {
    drivingTendencyResult: Array,
    isValidOperationTimeByLoad: Boolean,
    isSpecifiedENC2: Boolean
  },
  data () {
    return {
      headers: [
        { text: this.$t('項目'), align: 'center', value: 'name', width: '180px' },
        // { text: '値', align: '', value: 'value', width: '60px' },
        { text: this.$t('判定'), align: '', value: 'judge', width: '120px' },
        { text: this.$t('コメント'), align: '', value: 'comment' }
      ]
    }
  },
  methods: {
  },
  computed: {
    showDtDescription () {
      return !this.isSpecifiedENC2 && this.isValidOperationTimeByLoad
    },
    items () {
      return this.drivingTendencyResult
        .filter(item => item.name !== '')
        .map(item => {
          if (!item.commentid.indexOf('HST') && !this.isValidOperationTimeByLoad) {
            item.name += ' ※'
          }
          item.value = Math.round(item.value * 10) / 10
          return item
        })
    },
    notApplicable () {
      return this.$t('「高速走行時間」について以下機種は判定対象外です。')
    },
    dtDescription () {
      return this.$t('「高速走行時間」判定にはENCファイル2を送付してください。')
    }
  },
  created () {
  }
}
</script>

<style scoped>
.dt-description {
  color: red;
  font-size: 14px;
}
</style>
