import { render, staticRenderFns } from "./Upload.vue?vue&type=template&id=66fbea58&scoped=true&"
import script from "./Upload.vue?vue&type=script&lang=js&"
export * from "./Upload.vue?vue&type=script&lang=js&"
import style0 from "./Upload.vue?vue&type=style&index=0&id=66fbea58&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66fbea58",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCol,VContainer,VFileInput,VRadio,VRadioGroup,VRow,VSheet})
