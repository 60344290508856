<template>
  <v-container class="mt-4">
    <v-card class="elevation-2 px-2 py-1">
      <v-row>
        <v-col class="title pa-6">{{ $t("SDS判定情報") }}</v-col>
      </v-row>
      <v-row>
        <v-col class="px-6 sds-description">{{sdsDescription}}</v-col>
      </v-row>
      <v-simple-table
        v-if="thresholdResult.length > 0"
        class="elevation-0"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th rowspan="2" class="text-center">
                {{ $t("名称") }}
              </th>
              <th rowspan="2" class="text-center">
                {{ $t("値") }}
              </th>
              <th rowspan="2" class="text-center">
                {{ $t("単位") }}
              </th>
              <th colspan="2" class="text-center">
                {{ $t("適正範囲") }}
              </th>
              <th rowspan="2" class="text-center">
                {{ $t("判定") }}
              </th>
            </tr>
            <tr>
              <th class="text-center">{{ $t("最小") }}</th>
              <th class="text-center">{{ $t("最大") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in thresholdResult" :key="['item', idx].join('-')">
              <td v-for="(row, i2) in item"
                :key="['row', i2].join('-')"
                :rowspan="row.row"
                align="center"
              >
                <v-chip
                  v-if="i2 === item.length - 1 && (row.value === 'OK' || row.value === 'NG')"
                  :color="getColor(row.value)"
                  dark
                >{{row.value}}</v-chip>
                <span v-else>{{row.value}}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-row class="mt-4 px-8 font-weight-black">{{ $t("表中の値はアイドル、完暖機時の標準的な数値を示す。") }}</v-row>
    <v-row class="px-8 font-weight-black">{{ $t("バッテリ電圧値はバッテリ容量によっては範囲以下となる場合がある。") }}</v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Threshold',
  props: {
    thresholdResult: Array,
    sdsDescription: String
  },
  data () {
    return {
      headers: [
        { text: this.$t('名称'), align: 'center', value: 'name' }
      ]
    }
  },
  methods: {
    getColor (value) {
      if (value === 'NG') {
        return 'red'
      } else if (value === 'OK') {
        return 'primary'
      }
      return 'orange'
    }
  },
  created () {
  },
  computed: {
  }
}
</script>

<style scoped>
.sds-description {
  color: red;
  font-size: 14px;
}
</style>
