<template>
  <v-container class="mt-4">
    <v-card class="elevation-1 px-2 py-1">
      <v-row>
        <v-col class="title pa-6">{{ $t("オイル交換情報") }}</v-col>
      </v-row>
      <v-row>
        <v-col v-show="showOilDescription" class="px-6 py-7 oil-description">{{oilDescription}}</v-col>
      </v-row>
      <v-data-table
        dense
        hide-default-footer
        :headers="headers"
        :items="items"
        class="elevation-0"
      ></v-data-table>
    </v-card>
    <v-row class="mt-1" justify="end" v-show="!isValidOperationTimeByLoad">
      <v-col md="7" style="white-space: pre;">{{notApplicable}}</v-col>
    </v-row>
    <!-- <v-row class="mt-1" justify="end" v-show="isValidOperationTimeByLoad && !isOilFilesExist">
      <v-col md="7" style="white-space: pre;">{{showOilChangeTimeDescription}}</v-col>
    </v-row> -->
    <v-row class="mt-1" justify="end" v-show="oilcheckResult.isOilChangeCalculable && oilcheckResult.isOilChangeRecommend">
      <v-col md="7" style="white-space: pre;">{{showOilChangeRecommendDescription}}</v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'OilCheck',
  props: {
    oilcheckResult: Object,
    isValidOperationTimeByLoad: Boolean,
    isOilFilesExist: Boolean,
    isSpecifiedENC2: Boolean
  },
  data () {
    return {
      headers: [
        { text: this.$t('項目'), align: 'center', value: 'name' },
        { text: this.$t('値'), align: '', value: 'value' }
      ]
    }
  },
  methods: {
    parseValue (val) {
      if (val == null || isNaN(val)) {
        return '-'
      }
      return parseInt(val, 10)
    }
  },
  computed: {
    showOilDescription () {
      return !this.isSpecifiedENC2 && this.isValidOperationTimeByLoad
    },
    items () {
      const canShowOilChangeTime = this.isValidOperationTimeByLoad && this.isOilFilesExist
      const oilChangeTime = canShowOilChangeTime ? this.oilcheckResult.oilChangeTime : '―'
      const annotation = this.isValidOperationTimeByLoad ? '' : ' ※'
      return [
        { name: this.$t('オイル交換からの運転時間 [時間]'), value: this.parseValue(this.oilcheckResult.elapsedTime) },
        { name: this.$t('オイル交換推奨時期 [時間]') + annotation , value: this.parseValue(oilChangeTime) }
        // { name: '総運転時間(補正前) [分]', value: this.parseValue(this.isOilFilesExist) },
        // { name: '総運転時間(補正後) [分]', value: this.parseValue(this.oilcheckResult.correctedTotalTime) }
      ]
    },
    notApplicable () {
      return this.$t('「オイル交換推奨時間」について以下機種は判定対象外です。')
    },
    showOilChangeTimeDescription () {
      return this.$t('オイル交換推奨時期表示には前回のENCファイルを送付してください。')
    },
    oilDescription () {
      return this.$t('オイル交換情報表示にはENCファイル2を送付してください。')
    },
    showOilChangeRecommendDescription () {
      return this.$t('オイル交換時期です。交換を推奨します。')
    }
  },
  created () {
  }
}
</script>

<style scoped>
.oil-description {
  color: red;
  font-size: 14px;
}
</style>
