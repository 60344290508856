import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const locale = (
  window.navigator.userLanguage ||
    window.navigator.language ||
    window.navigator.browserLanguage
).substr(0, 2)

export default new VueI18n({
  locale,
  messages: {
    ja: require('../assets/lang/ja.json'),
    en: require('../assets/lang/en.json')
  }
})
