<template>
  <v-container class="mx-auto my-6">
    <v-row>
      <v-file-input
        multiple
        show-size
        dense
        chips
        :label="$t('現在のENCファイルの選択')"
        v-model="encFiles"
        accept=".enc"
      />
    </v-row>
    <v-row>
      <v-file-input
        multiple
        show-size
        dense
        chips
        :label= "$t('前回のENCファイルの選択')"
        v-model="oilFiles"
        accept=".enc"
      />
    </v-row>
    <v-row v-if="false">
      <v-col class="py-0">
        <v-radio-group v-model="radioGDPR" row>
          <template v-slot:label>
            <div>{{ $t("エンジン号機を提供する") }}</div>
          </template>
          <v-radio label="YES" :value="true"></v-radio>
          <v-radio label="NO" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-btn
          block
          light
          color="primary"
          @click="onUpload"
          :loading="isLoading"
          class="white--text"
          style="text-transform: none"
          >{{ $t("送信") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-sheet>
      <BaseInfo :baseInfo="baseInfo" />
      <ThresholdResult
        v-if="sdsDescription.length > 0 || thresholdResult.length > 0"
        :sdsDescription="sdsDescription"
        :thresholdResult="thresholdResult"
      />
      <OilCheckResult
        v-if="oilcheckResult"
        :oilcheckResult="oilcheckResult"
        :isValidOperationTimeByLoad="isValidOperationTimeByLoad"
        :isOilFilesExist="isOilFilesExist"
        :isSpecifiedENC2="isSpecifiedENC2"
      />
      <DrivingTendency
        v-if="drivingTendencyResult.length > 0"
        :drivingTendencyResult="drivingTendencyResult"
        :isValidOperationTimeByLoad="isValidOperationTimeByLoad"
        :isSpecifiedENC2="isSpecifiedENC2"
      />
    </v-sheet>
    <v-row v-if="errMsg.length > 0" class="my-3 upload-error">
      <v-col>{{ errMsg }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseInfo from '@/components/BaseInfo'
import ThresholdResult from '@/components/ThresholdResult'
import OilCheckResult from '@/components/OilCheckResult'
import DrivingTendency from '@/components/DrivingTendency'
import Utils from '@/assets/js/Utils.js'
import i18n from '@/plugins/i18n'

export default {
  name: 'Upload',
  props: {
    msg: String
  },
  components: {
    BaseInfo,
    ThresholdResult,
    OilCheckResult,
    DrivingTendency
  },
  data: () => {
    return {
      encFiles: [],
      oilFiles: [],
      isLoading: false,
      radioGDPR: false,
      baseInfo: [],
      sdsDescription: '',
      thresholdResult: [],
      oilcheckResult: null,
      drivingTendencyResult: [],
      isValidOperationTimeByLoad: false,
      isSpecifiedENC2: false,
      errMsg: ''
    }
  },
  methods: {
    initOutputResult () {
      this.baseInfo = []
      this.sdsDescription = ''
      this.thresholdResult = []
      this.oilcheckResult = null
      this.drivingTendencyResult = []
      this.errMsg = ''
    },
    async onUpload () {
      if (this.encFiles == null || this.encFiles.length === 0) {
        alert(this.$t('ENCファイルを選択してください。'))
        return
      }
      // if (this.oilFiles.length === 1) {
      //   alert('オイル交換時期計算用ENC1、ENC2ファイルを選択してください。')
      //   return
      // }
      // this.isLoading = true
      this.initOutputResult()
      window.gEmitter.emit('update-loading-state', true)
      const reqData = {
        enc: [],
        oil: [],
        gdpr: this.radioGDPR
      }
      for (let i = 0; i < this.encFiles.length; ++i) {
        const base64Enc = await Utils.readFile(this.encFiles[i])
        reqData.enc.push(base64Enc)
      }
      for (let i = 0; i < this.oilFiles.length; ++i) {
        const base64Enc = await Utils.readFile(this.oilFiles[i])
        reqData.oil.push(base64Enc)
      }
      const lang = i18n.locale
      const reqParam = {
        lang: lang
      }
      // web, web-internalで呼び出しメソッドを変える事
      // web: authUploadPost
      // web-internal: uploadPost
      window.gApigClient
        .authUploadPost(reqParam, reqData)
        .then((res) => {
          // console.log(res.data)
          // console.log(JSON.stringify(res.data, false, '  '))
          window.gEmitter.emit('update-loading-state', false)
          if (
            Object.prototype.hasOwnProperty.call(res.data, 'ENC1') &&
            Object.prototype.hasOwnProperty.call(res.data.ENC1, 'items')
          ) {
            this.sdsDescription = res.data.ENC1.description
            this.formatThresholdResult(res.data.ENC1.items)
          }
          if (Object.prototype.hasOwnProperty.call(res.data, 'ENC2')) {
            this.oilcheckResult = res.data.ENC2
          }
          if (
            Object.prototype.hasOwnProperty.call(res.data, 'drivingTendency')
          ) {
            this.drivingTendencyResult = res.data.drivingTendency
          }
          this.baseInfo = res.data.baseInfo
          this.isValidOperationTimeByLoad = res.data.isValidOperationTimeByLoad
          this.isOilFilesExist = this.oilFiles.length != 0 ? true : false
          this.isSpecifiedENC2 = res.data.isSpecifiedENC2
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err)
          window.gEmitter.emit('update-loading-state', false)
          this.isLoading = false
          this.errMsg = [err.data.msg, err.data.detail].join(': ')
        })
    },
    formatThresholdResult (items) {
      this.thresholdResult = []
      items.forEach((item) => {
        item.units.forEach((unit, idx) => {
          const row = []
          if (idx === 0) {
            row.push({ row: item.units.length, value: item.name })
          }
          row.push({ row: 1, value: unit.value })
          row.push({ row: 1, value: unit.unit })
          row.push({ row: 1, value: unit.range[0] })
          row.push({ row: 1, value: unit.range[1] })
          if (unit.result === true) {
            row.push({ row: 1, value: 'OK' })
          } else if (unit.result === false) {
            row.push({ row: 1, value: 'NG' })
          } else {
            row.push({ row: 1, value: 'ー' })
          }
          this.thresholdResult.push(row)
        })
      })
    }
  },
  created () {},
  computed: {
    uploadUrl () {
      return process.env.VUE_APP_SDS_UPLOAD_URL
    }
  }
}
</script>

<style scoped>
.upload-error {
  color: red;
  font-weight: 600;
}
</style>
