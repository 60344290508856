<template>
  <div class="full" v-show="isLoading">
    <div class="loader mid">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  components: {
  },
  data: () => {
    return {
      isLoading: false
    }
  },
  created () {
    window.gEmitter.on('update-loading-state', (isLoading) => {
      this.isLoading = isLoading
    })
  },
  beforeUnmount () {
    window.gEmitter.off('update-loading-state')
  }
}
</script>

<style scoped>
.full {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(192, 192, 192, 0.2);
  z-index: 20000;
}
.loader.mid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
